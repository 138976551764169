.json-object {
    margin-left: 20px;
    text-align: left;
  }
  
  .json-key-value {
    margin-bottom: 10px;  /* Adjust as needed */
  }
  
  .json-key {
    margin-right: 5px;  /* Adjust as needed */
  }
  

  
  .json-array {
    margin-top: 5px;  /* Adjust as needed */
    margin-bottom: 5px;  /* Adjust as needed */
  }
  
  .json-array-item {
    margin-left: 20px;  /* Adjust as needed */
  }
  