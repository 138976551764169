/* Main container */
#root {
  font-family: Arial, sans-serif;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Heading styles */
h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 16px;
}

h3 {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 16px;
}

h4 {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 16px;
}

/* Textarea styles */
textarea {
  width: 100%;
  height: 150px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

/* Button styles */
button {
  background-color: #007bff;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* PlantUML Diagram styles */
PlantUMLDiagram {
  display: block;
  margin: 20px auto;
}

.main-container
{
      width: 100%;
        margin: 0 auto;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: #f4f4f4;
        border: 1px solid #ccc;
}
