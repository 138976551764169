.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.App-logo {
  font-size: 5rem;
  color: #61dafb;
  margin-bottom: 10px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

h1 {
  font-size: 2.5rem;
  color: #f0f0f0;
}

h2 {
  font-size: 1.5rem;
}

.App-body {
  display: flex;
  justify-content: space-between;
  background-color: #f4f4f4;
}

.left-panel, .right-panel {
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.left-panel {
  flex: 0.3;
  padding: 20px;
}

.right-panel {
  flex: 0.7;
  padding: 20px;
}

.left-panel ul {
  list-style: none;
  padding: 0;
}

.left-panel ul li {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 5px;
}

.left-panel ul li:hover {
  background-color: #f0f0f0;
}

.left-panel ul li i.material-icons {
  margin-right: 8px;
}

.greyed-out {
  color: grey;
  cursor: not-allowed;
  background-color: #a9a7a7;
}

footer {
  position: fixed;
  bottom: 0%;
  width: 100%;
}

.iconText {
  margin: 0 0.5rem;
}

.navbarStyle {
  padding: 0.5rem 1rem !important;
}

.navbarButton {
  color: #fff !important;
  padding: 0.5rem 1rem !important;
}
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup input[type="password"] {
  margin-right: 10px;  /* Add right margin to the API Key input field */
}

.popupButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.iconText {
  margin: 0 0.5rem;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.todo-form {
  width: 60%;
}

.todo-list {
  width: 60%;
}

.todo-label {
  font-size: large;
  margin-right: 22%;
  margin-left: 3%;
}

.todo-view-btn {
  float: right;
}

.tableColumn {
  word-break: break-all;
}

.table-responsive {
  max-height: 37rem !important;
}

.warningMessage {
  color: red;
}

.card-title {
  background-color: #007bff;
  text-align: center;
}

.signInButton {
  margin: 1rem;
}

.genericButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  font-size: large;
  font-weight: bold;
}

.genericButton:hover {
  background-color: #0359b5;
}

.info-icon-link {
  margin-left: 20px;
}
