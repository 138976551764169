.formatted-output {
    font-size: 1em;
    line-height: 1.6;
    text-align: left;
  }
  
  .section-title {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .bullet-list {
    margin-left: 20px;
    list-style-type: decimal;
  }
  
  .bullet-item {
    margin-bottom: 8px;
  }
  