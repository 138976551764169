/* Main container styling */
.ideaSorter-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
  }
  
  /* Label styling */
  .ideaSorter-label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  
  /* Textarea styling */
  .ideaSorter-textArea {
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
  }
  
  /* Button styling */
  .ideaSorter-sortButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ideaSorter-sortButton:hover {
    background-color: #0056b3;
  }
  
  /* Loader styling */
  .ideaSorter-loader {
    margin-top: 20px;
  }
  
  /* Sorted ideas container */
  .ideaSorter-sortedIdeas {
    margin-top: 30px;
    padding: 15px;
    border: 1px solid #ccc;
  }

  .ideaSorter-sortedIdeas th {
    text-align: center;
    background-color: #b4b1b1;
  }

  .ideaSorter-sortedIdeas td {
    text-align: left;
  }
  
  /* Table styling */
  .ideaSorter-table {
    width: 100%;
    border-collapse: collapse;
    
  }
  
  .ideaSorter-thead {
    background-color: #9b0c0c;
    text-align: center;
  }
  
  .ideaSorter-th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  .ideaSorter-td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
  
  .ideaSorter-tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Error display */
  .ideaSorter-error {
    color: red;
  }
  