/* Container */
.container {
    margin: 0 auto;
    width: 100%;
    padding: 40px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  /* Label */
  .label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  /* Textarea */
  .text-area {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  /* Button */
  .generate-button {
    padding: 12px 24px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
  }
  
  .generate-button:hover {
    background-color: #0056b3;
  }
  
  /* Loader */
  .loader {
    font-size: 18px;
    font-weight: 600;
  }
  
  /* Generated Press Release */
  .generated-press-release {
    margin-top: 20px;
    text-align: left;
  }
  
  .generated-press-release h3 {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
  }
  
  .generated-press-release pre {
    background-color: #e9ecef;
    padding: 15px;
    border-radius: 4px;
    overflow-y: auto;  /* Added to make it scrollable */
    max-height: 300px;  /* Added to limit the height */
    white-space: pre-wrap;  /* Added to break lines */
    text-align: left;  /* Added to ensure the text is left-aligned */
  }
  

  /* Header section with label and info button */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-text {
    flex-grow: 1;
    text-align: center;  /* Center-aligns the text */
  }
  
  .sub-header {
    font-size: 0.9em;  /* Slightly smaller font size */
    color: grey;       /* Gray color */
  }
  
  /* Info/Help Button */
  .info-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #007bff;
  }
  
  /* Help Modal */
  .help-modal {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .help-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 400px;
  }
  
  .close-button {
    position: absolute;  /* Position relative to the parent element, which has 'position: relative' */
    top: 10px;           /* 10px from the top edge of the parent element */
    right: 10px;         /* 10px from the right edge of the parent element */
    font-size: 48px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 2;          /* Ensure it is above other content */
    color: #007bff;
  }
