.form-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .category-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .category-item {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-button:hover {
    background-color: #0056b3;
  }
  
  .checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
  }
  
  .checkbox-item input {
    margin-right: 10px;
  }

  /* Existing styles ... */

.output-container {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    overflow: auto;
    min-height: 100px;
    max-height: 500px;
  }
  
  .output-container h3 {
    margin-top: 0;
  }
  
 .test-cases-container {
        width: 100%;
          margin: 0 auto;
          padding: 20px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          background-color: #f4f4f4;
          border: 1px solid #ccc;
 }
  