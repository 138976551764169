.formatted-output {
    /* Styles for the formatted output container */
  }
  
  .section-title {
    font-weight: bold;
  }
  
  .bullet-list {
    list-style: disc;
  }
  
  .bullet-item {
    margin-left: 20px;
  }
  