/* User Story Component Styles */
.user-story-container {
    padding: 15px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
  }
  
  .user-story-select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .user-story-textarea {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    box-sizing: border-box;
  }
  
  .user-story-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .user-story-button:hover {
    background-color: #0056b3;
  }

  .user-story-button:disabled {
    background-color: #ccc; /* Grey color */
    color: #666; /* Darker grey text to ensure readability */
    cursor: not-allowed; /* The cursor to display when the mouse pointer is over the button */
  }
  
  
  .user-story-output {
    margin-top: 20px;
  }
  

  .label {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .labeldiv {
    text-align: left;
  }

  
  .toggle-container {
    text-align: left;
  }
  
  .user-story-uploader {
    text-align: left;
  }