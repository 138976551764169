.output-container {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
  }
  
  .output-title {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 8px;
  }
  
  .output-content {
    font-size: 1em;
    line-height: 1.6;
  }
  
  .user-story-output {
    position: relative;
  }
  
  .copy-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  