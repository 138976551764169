.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* or 'row' if you want them side by side */
    gap: 10px; /* adjust as needed for spacing */
  }

  .loader-text {
    /* style for your text, ensure it fits or wraps as needed */
    text-align: center;
  }