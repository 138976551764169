.container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 60%;
    margin: auto;
    box-sizing: border-box;  /* Ensures padding is included in container's width */
  }
  
  .label {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
  }
  .app .label {
    text-align: left !important;
  }
  
  
  .text-area {
    width: calc(100% - 20px);  /* Reduces the width of the text area */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;  /* Ensures padding is included in text area's width */
  }
  
  .generate-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .generate-button:hover {
    background-color: #0056b3;
  }
  
  .release-notes {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .release-notes pre {
    white-space: pre-wrap;
    word-wrap: break-word;  /* Wrap long words onto the next line */
    overflow: auto;    /* Enable vertical scrolling if needed */
    max-width: 100%;       /* Restrict max width */
    min-width: 50%;        /* Optional: Set a minimum width */
    text-align: left;  /* Align the text to the left */
    height: 500px;
  }
  